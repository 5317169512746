import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import {
    alpha,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    matchIsValidTel,
    MuiTelInput,
    MuiTelInputCountry,
} from 'mui-tel-input';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { GaAction, GaCategory } from '../../config/googleAnalytics';
import { useError } from '../../hooks/useError';
import { useFetchResource } from '../../hooks/useFetchResource';
import { useLogger } from '../../hooks/useLogger';
import { useMe } from '../../hooks/useMe';
import { useOrganization } from '../../hooks/useOrganization';
import { usePreventPageLeave } from '../../hooks/usePreventPageLeave';
import { EncryptedObjectIdString, ObjectIdString } from '../../types/common';
import { OrganizationModel } from '../../types/model/organization';
import { logAnalyticsEvent } from '../../utils/logger/logAnalyticsEvent';
import { FormSkeleton } from '../skeleton';

function getDefaultCountryCode(): string {
    const browserLocale = navigator.language;
    if (browserLocale) {
        const countryCode = browserLocale.split('-')[1];
        if (countryCode && countryCode.length === 2) {
            return countryCode.toUpperCase();
        }
    }
    return 'US';
}

interface UserDataInOrganizationProps {
    label: string;
    phone: string;
    encryptedClientId: EncryptedObjectIdString;
    userId: ObjectIdString;
}
const LABEL_MAX_LENGTH = 150;
// const PHONE_MAX_LENGTH = FieldSize.S;
export const UserDataInOrganization = ({
    label,
    phone,
    encryptedClientId,
    userId,
}: UserDataInOrganizationProps) => {
    const { fetchOrganization } = useOrganization();
    const [organization, setOrganization] = useState<OrganizationModel | null>(
        null,
    );
    const cardSx = {
        boxShadow: `0 0 15px ${alpha('#7986cb', 0.2)}, 0 0 30px ${alpha('#5c6bc0', 0.1)}`,
    };
    const [consentChecked, setConsentChecked] = useState(false);
    const intl = useIntl();
    const { error, handleError } = useError();
    const logger = useLogger();
    useFetchResource({
        fetchFn: fetchOrganization,
        dependencies: [encryptedClientId],
        params: { encryptedClientId },
        resourceName: 'organization',
        setData: setOrganization,
        onError: handleError,
    });
    const { addOrUpdatePersonalData } = useMe();

    const [currentData, setCurrentData] = useState({
        label,
        phone,
    });
    const [isEditing, setIsEditing] = useState(!phone || !label);

    const [editedData, setEditedData] = useState({
        phone,
        label,
    });

    const [isFormDirty, setIsFormDirty] = useState(false);

    usePreventPageLeave({
        isEnabled: isFormDirty && isEditing,
    });
    const [errors, setErrors] = useState({
        label: '',
        phone: '',
    });
    const validateLabel = (value: string): string => {
        if (value.length > LABEL_MAX_LENGTH) {
            return intl.formatMessage(
                { id: 'validation_max_length' },
                { maxLength: LABEL_MAX_LENGTH },
            );
        }
        return '';
    };

    const validatePhone = (value: string): string => {
        if (value && !matchIsValidTel(value)) {
            return intl.formatMessage({ id: 'validation_invalid_phone' });
        }
        // if (value.length > PHONE_MAX_LENGTH) {
        //     return intl.formatMessage(
        //         { id: 'validation_string_length_maximum_web' },
        //         { maxLength: PHONE_MAX_LENGTH },
        //     );
        // }
        return '';
    };
    const handleLabelChange = (value: string) => {
        const error = validateLabel(value);
        setErrors((prev) => ({
            ...prev,
            label: error,
        }));
        setEditedData((prev) => ({
            ...prev,
            label: value,
        }));
        setIsFormDirty(true);
    };

    const handlePhoneChange = (value: string) => {
        const error = validatePhone(value);

        setErrors((prev) => ({
            ...prev,
            phone: error,
        }));
        setEditedData((prev) => ({
            ...prev,
            phone: value,
        }));
        setIsFormDirty(true);
    };

    const handleEdit = () => {
        setIsEditing(true);
        setConsentChecked(false);
    };

    const handleSave = async () => {
        try {
            await addOrUpdatePersonalData(
                encryptedClientId,
                editedData.label,
                editedData.phone,
                userId,
            );
            setCurrentData({
                label: editedData.label,
                phone: editedData.phone,
            });
            setIsEditing(false);
            setIsFormDirty(false);
            logAnalyticsEvent({
                category: GaCategory.Button,
                action: GaAction.Click,
                label: 'personal-data-update',
                eventName: 'personal-data-update',
            });
        } catch (error) {
            logger.error('Failed to update personal data', error as Error);
        }
    };
    if (!organization) return <FormSkeleton />;
    if (error) {
        throw error;
    }
    if (isEditing) {
        return (
            <Card sx={cardSx}>
                <CardContent>
                    {organization.logoSignedUrl && (
                        <img
                            style={{ maxWidth: '30%' }}
                            src={organization.logoSignedUrl}
                        ></img>
                    )}
                    <TextField
                        label={intl.formatMessage({ id: 'display_name' })}
                        value={editedData.label}
                        onChange={(e) => handleLabelChange(e.target.value)}
                        fullWidth
                        slotProps={{
                            htmlInput: { maxLength: LABEL_MAX_LENGTH },
                        }}
                        error={!!errors.label}
                        helperText={`${editedData.label.length}/${LABEL_MAX_LENGTH}`}
                        variant="outlined"
                        size="medium"
                        sx={{ mb: 2, mt: 1 }}
                        data-qa="label-input"
                    />
                    <MuiTelInput
                        label={intl.formatMessage({ id: 'phone_number' })}
                        value={editedData.phone}
                        error={!!errors.phone}
                        onChange={handlePhoneChange}
                        fullWidth
                        size="medium"
                        defaultCountry={
                            getDefaultCountryCode() as MuiTelInputCountry
                        }
                        sx={{ mb: 2 }}
                        helperText={errors.phone}
                        data-qa="phone-input"
                    />
                    <FormControlLabel
                        data-qa="data-processing-consent-checkbox"
                        control={
                            <Checkbox
                                checked={consentChecked}
                                onChange={(e) =>
                                    setConsentChecked(e.target.checked)
                                }
                                color="primary"
                            />
                        }
                        label={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    flexWrap: 'nowrap',
                                }}
                                data-qa="data-processing-consent"
                            >
                                {intl.formatMessage({
                                    id: 'data_processing_consent_short',
                                })}
                                <Tooltip
                                    title={intl.formatMessage(
                                        { id: 'data_processing_consent' },
                                        {
                                            organizationName:
                                                organization.label,
                                        },
                                    )}
                                    arrow
                                >
                                    <InfoIcon
                                        sx={{
                                            fontSize: '1rem',
                                            color: 'text.secondary',
                                            cursor: 'help',
                                            minWidth: '20px',
                                        }}
                                    />
                                </Tooltip>
                            </Box>
                        }
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                            disabled={
                                !consentChecked ||
                                Boolean(errors.label) ||
                                Boolean(errors.phone)
                            }
                            data-qa="save-button"
                        >
                            {intl.formatMessage({ id: 'save' })}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card sx={{ ...cardSx, width: '100%' }}>
            <CardHeader
                title={intl.formatMessage({ id: 'your_data' })}
                subheader={intl.formatMessage(
                    { id: 'in_company' },
                    { organizationLabel: organization.label },
                )}
                action={
                    organization.logoSignedUrl && (
                        <Box
                            component="img"
                            src={organization.logoSignedUrl}
                            // alt={organization.label}
                            sx={{
                                width: 128,
                                height: 64,
                                objectFit: 'contain',
                                flexShrink: 0,
                            }}
                        />
                    )
                }
            />
            <CardContent sx={{ p: 3 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'flex-start',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    color: 'text.secondary',
                                }}
                                data-qa="display-name"
                            >
                                {intl.formatMessage({ id: 'display_name' })}:{' '}
                                {currentData.label}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    color: 'text.secondary',
                                }}
                                data-qa="phone-number"
                            >
                                {intl.formatMessage({ id: 'phone_number' })}:{' '}
                                {currentData.phone}
                            </Typography>
                        </Box>
                    </Box>
                    <IconButton
                        onClick={handleEdit}
                        sx={{
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                        data-qa="edit-button"
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
};
