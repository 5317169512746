import { useAuth0 } from '@auth0/auth0-react';
import { Button, SxProps } from '@mui/material';
import mail from '../../assets/mail.svg';
import { useIntl } from 'react-intl';
import { getRedirectLoginOptions } from './getRedirectLoginOptions';

export const EmailCodeLoginButton = ({ sx }: { sx?: SxProps }) => {
    const { loginWithRedirect } = useAuth0();
    function loginWithEmailCode() {
        loginWithRedirect(getRedirectLoginOptions('email'));
    }
    const intl = useIntl();

    return (
        <Button
            variant="contained"
            color="primary"
            size="large"
            sx={sx}
            onClick={loginWithEmailCode}
            startIcon={<img height="20px" src={mail} />}
            data-qa="email-code-login-button"
        >
            {intl.formatMessage({ id: 'login_page_email_code_login' })}
        </Button>
    );
};
