import { StepButton, StepLabel, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import Stepper from '@mui/material/Stepper';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useError } from '../hooks/useError';
import { useFetchResource } from '../hooks/useFetchResource';
import { useSimpleForm } from '../hooks/useSimpleForm';
import { themeColors } from '../theme';
import {
    UrlParam,
    useOptionalObjectIdParam,
    useRequiredEncryptedObjectIdParam,
} from '../types/common';
import { SimpleFormDto } from '../types/dto/simpleForm';
const mainGradient = `linear-gradient(to right, ${themeColors.green} 10%, ${themeColors.yellowMain} 20%,  ${themeColors.yellowMain} 40%, #FFC107 70%, #283593 100%)`;

export const FormStepper = ({
    activeStepIndex,
}: {
    activeStepIndex: number;
}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const encryptedSimpleFormId = useRequiredEncryptedObjectIdParam(
        UrlParam.encryptedSimpleFormId,
    );
    const { fetchSimpleForm } = useSimpleForm();
    const [simpleForm, setSimpleForm] = useState<SimpleFormDto | null>(null);
    const { error, handleError } = useError();

    useFetchResource({
        fetchFn: fetchSimpleForm,
        dependencies: [encryptedSimpleFormId],
        params: { encryptedSimpleFormId },
        resourceName: 'simple form',
        setData: setSimpleForm,
        onError: handleError,
    });
    const projectId = useOptionalObjectIdParam(UrlParam.projectId);
    const issueId = useOptionalObjectIdParam(UrlParam.issueId);
    const fileUploaderPath = `/form/${encryptedSimpleFormId}/project/${projectId}/issue/${issueId}`;
    const userOnOrganizationPath = `/form/${encryptedSimpleFormId}/project/${projectId}/issue/${issueId}/organization/${simpleForm?.encryptedClientId}/user`;
    const redirectToFileUploader = useCallback(() => {
        navigate(fileUploaderPath);
    }, [navigate, fileUploaderPath]);
    const redirectToUserOnOrganization = useCallback(() => {
        navigate(userOnOrganizationPath);
    }, [navigate, userOnOrganizationPath]);
    if (error) {
        throw error;
    }
    const steps = [
        {
            label: intl.formatMessage({ id: 'stepper_create_issue' }),
            disabled: activeStepIndex > 0,
            disabledReason: intl.formatMessage({
                id: 'tooltip_cannot_edit_issue',
            }),
        },
        {
            label: intl.formatMessage({ id: 'stepper_add_files' }),
            disabled: activeStepIndex === 0,
            disabledReason: intl.formatMessage({
                id: 'tooltip_cannot_add_files_before_issue_creation',
            }),
            redirectHandler: redirectToFileUploader,
        },
        {
            disabled: activeStepIndex === 0,
            label: intl.formatMessage({ id: 'stepper_add_personal_data' }),
            redirectHandler: redirectToUserOnOrganization,
        },
    ];

    return (
        <Box sx={{ width: '100%', mt: 10 }}>
            <Stepper
                activeStep={activeStepIndex}
                alternativeLabel
                sx={{
                    '& .MuiStepLabel-root': {
                        '& .MuiStepLabel-labelContainer': {
                            marginTop: 1,
                            '& .MuiStepLabel-label': {
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                '&.Mui-active': {
                                    color: 'secondary.main',
                                    fontWeight: 800,
                                },
                            },
                        },
                    },
                    '& .MuiStepIcon-root': {
                        width: 36,
                        height: 36,
                        zIndex: 999999,
                        transition: 'all 0.3s ease',
                        '&.Mui-active': {
                            color: 'secondary.main',
                        },
                        '&.Mui-completed': {
                            color: '#4CAF50',
                        },
                        '& .MuiStepIcon-text': {
                            fill: 'white',
                            fontSize: '0.875rem',
                            fontWeight: 600,
                        },
                    },
                }}
            >
                {steps.map((step, index) => (
                    <Tooltip
                        key={index}
                        title={step.disabled ? step.disabledReason : ''}
                    >
                        <Step
                            key={index}
                            disabled={step.disabled}
                            sx={{
                                cursor: step.disabled ? 'auto' : 'pointer',
                            }}
                        >
                            <StepButton
                                color="inherit"
                                onClick={step.redirectHandler}
                                sx={{
                                    paddingY: '19px',
                                    fontWeight: 600,
                                    '& .MuiStepLabel-iconContainer': {
                                        zIndex: 1,
                                        transform:
                                            index === activeStepIndex
                                                ? 'scale(1.4)'
                                                : 'scale(1)',
                                        transition: 'transform 0.3s ease',
                                    },
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        '& .MuiStepLabel-iconContainer': {
                                            transform: 'scale(1.1)',
                                            '& .MuiStepIcon-root': {
                                                color: 'secondary.main',
                                            },
                                        },
                                        '& .MuiStepLabel-label': {
                                            color: 'secondary.main',
                                        },
                                        '& .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label':
                                            {
                                                fontWeight: '600',
                                            },
                                    },
                                }}
                            >
                                <StepLabel>{step.label}</StepLabel>
                            </StepButton>
                            {index > 0 && (
                                <StepConnector
                                    sx={{
                                        zIndex: 0,
                                        '& .MuiStepConnector-line': {
                                            height: 3,
                                            transition: 'all 0.5s ease-in-out',
                                            animation:
                                                activeStepIndex === index
                                                    ? 'gradientFlow 1s ease forwards'
                                                    : 'none',
                                            '@keyframes gradientFlow': {
                                                '0%': {
                                                    backgroundImage:
                                                        'linear-gradient(to right, #e0e0e0, #e0e0e0)',
                                                    backgroundSize: '0% 100%',
                                                },
                                                '100%': {
                                                    backgroundImage:
                                                        mainGradient,
                                                    backgroundSize: '100% 100%',
                                                },
                                            },
                                        },
                                        '@keyframes completedFlow': {
                                            '0%': {
                                                backgroundImage: mainGradient,
                                            },
                                            '100%': {
                                                backgroundImage:
                                                    'linear-gradient(to right, #4CAF50, #4CAF50)',
                                            },
                                        },
                                    }}
                                />
                            )}
                        </Step>
                    </Tooltip>
                ))}
            </Stepper>
        </Box>
    );
};
