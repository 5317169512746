import { Box, Button, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { UserDataInOrganization } from '../components/organization/card';
import { FormSkeleton } from '../components/skeleton';
import { useMe } from '../hooks/useMe';
import { UrlParam, useRequiredEncryptedObjectIdParam } from '../types/common';
import {
    identifyUserAndPage,
    PageName,
} from '../utils/locale/identifyUserAndPage';

export const UserOnOrganizationForm = () => {
    const { me, loading } = useMe();
    const encryptedClientId = useRequiredEncryptedObjectIdParam(
        UrlParam.encryptedClientId,
    );
    const intl = useIntl();
    const navigate = useNavigate();
    const encryptedSimpleFormId = useRequiredEncryptedObjectIdParam(
        UrlParam.encryptedSimpleFormId,
    );
    const redirectToCreateIssue = useCallback(() => {
        navigate(`/form/${encryptedSimpleFormId}`);
    }, [navigate, encryptedSimpleFormId]);

    if (!me || !encryptedClientId || loading) {
        return <FormSkeleton />;
    }
    identifyUserAndPage(me._id, PageName.personalDataOnOrganization, me.email);

    const existingPersonalData = me.personalData.find(
        (data) => data.encryptedClientId === encryptedClientId,
    );

    return (
        <Box>
            <Box sx={{ padding: 3, mt: 2 }}>
                <Typography
                    variant="subtitle2"
                    sx={{ mb: 2, textAlign: 'center', whiteSpace: 'pre-line' }}
                >
                    {intl.formatMessage(
                        {
                            id: 'issue_submitted_message',
                        },
                        { email: me.email },
                    )}
                </Typography>
                <UserDataInOrganization
                    label={existingPersonalData?.label || ''}
                    phone={existingPersonalData?.phone || ''}
                    encryptedClientId={encryptedClientId}
                    userId={me._id}
                />
            </Box>
            <Button
                variant="outlined"
                color="secondary"
                size="large"
                sx={{
                    display: 'flex',
                    margin: 'auto',
                    mt: 1,
                    minWidth: '400px',
                }}
                onClick={redirectToCreateIssue}
            >
                {intl.formatMessage({
                    id: 'create_another_issue',
                })}
            </Button>
        </Box>
    );
};
