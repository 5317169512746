import { useIntl } from 'react-intl';
import { ProductFruits } from 'react-product-fruits';
import { useMe } from '../hooks/useMe';
import { getProductFruitsWorkspaceCode } from './environment';

export function InitProductFruits() {
    const { locale } = useIntl();
    const { me } = useMe();
    const getUsername = () => {
        try {
            if (!me?.personalData) return 'unknown username';
            if (!Array.isArray(me.personalData)) return 'unknown username';
            if (!me.personalData[0]?.label) return 'unknown username';

            return me.personalData[0].label;
        } catch {
            return 'unknown username';
        }
    };
    const userInfo = {
        email: me?.email,
        username: getUsername(),
        role: me?.basePermissions?.role,
    };
    const workspaceCode = getProductFruitsWorkspaceCode();
    if (!workspaceCode) {
        return null;
    }

    return (
        <ProductFruits
            workspaceCode={workspaceCode}
            language={locale}
            user={userInfo}
        />
    );
}
