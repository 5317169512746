import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useIntl } from 'react-intl';
import { useIntlContext } from '../contextProviders/useIntlContext';
import { ISO6391LanguageCode } from '../utils/locale/types';
interface LanguageOption {
    code: ISO6391LanguageCode;
    label: string;
    countryCode: string;
}

const LANGUAGES: LanguageOption[] = [
    {
        code: ISO6391LanguageCode.pl,
        label: 'Polski',
        countryCode: 'PL',
    },
    {
        code: ISO6391LanguageCode.en,
        label: 'English',
        countryCode: 'US',
    },
];

export const LanguageSelector: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { setLocale } = useIntlContext();
    const intl = useIntl();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (lang: ISO6391LanguageCode) => {
        setLocale(lang);
        handleClose();
    };

    return (
        <>
            <Tooltip title={intl.formatMessage({ id: 'change_language' })}>
                <IconButton onClick={handleOpen} sx={{ p: 1 }}>
                    <LanguageIcon />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {LANGUAGES.map((lang) => (
                    <MenuItem
                        key={lang.code}
                        onClick={() => handleLanguageChange(lang.code)}
                        sx={{
                            minWidth: '150px',
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        <ReactCountryFlag
                            countryCode={lang.countryCode}
                            svg
                            style={{
                                width: '24px',
                                height: '16px',
                            }}
                        />
                        {lang.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
