import React, { PropsWithChildren, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useDidMount } from '../hooks/useDidMount';
import { getLocaleFromBrowser } from '../utils/locale/getDefaultLocale';
import { getEscapedTranslations } from '../utils/locale/getEscapedTranslations';
import { setHtmlRootLangAttribute } from '../utils/locale/setHtmlRootLangAttribute';
import { ISO6391LanguageCode } from '../utils/locale/types';
import { IntlContext } from './intlContext';

const LOCALE_STORAGE_KEY = 'selected_locale';

export const IntlProviderWrapper = ({
    children,
}: PropsWithChildren): React.ReactElement => {
    const [locale, setCurrentLocale] = React.useState(() => {
        const savedLocale = localStorage.getItem(LOCALE_STORAGE_KEY);
        return (savedLocale as ISO6391LanguageCode) || getLocaleFromBrowser();
    });

    const setLocale = (newLocale: ISO6391LanguageCode): void => {
        setCurrentLocale(newLocale);
        setHtmlRootLangAttribute(newLocale);
        localStorage.setItem(LOCALE_STORAGE_KEY, newLocale);
    };

    useDidMount(() => {
        setHtmlRootLangAttribute(locale);
    });

    const translations = useMemo(
        () => getEscapedTranslations(locale),
        [locale],
    );

    return (
        <IntlContext.Provider
            value={{
                setLocale,
                messages: translations,
            }}
        >
            <IntlProvider
                key={locale}
                locale={locale}
                messages={translations}
                defaultLocale={getLocaleFromBrowser()}
            >
                {children}
            </IntlProvider>
        </IntlContext.Provider>
    );
};
