import { Box, Button, Grid, Typography } from '@mui/material';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { GaAction, GaCategory } from '../../config/googleAnalytics';
import { useError } from '../../hooks/useError';
import { useFetchResource } from '../../hooks/useFetchResource';
import { FileWithIdAndStatus, useFile } from '../../hooks/useFile';
import { useMe } from '../../hooks/useMe';
import { useSimpleForm } from '../../hooks/useSimpleForm';
import { buttonSx } from '../../theme';
import {
    UrlParam,
    useRequiredEncryptedObjectIdParam,
    useRequiredObjectIdParam,
} from '../../types/common';
import { SimpleFormDto } from '../../types/dto/simpleForm';
import {
    identifyUserAndPage,
    PageName,
} from '../../utils/locale/identifyUserAndPage';
import { logAnalyticsEvent } from '../../utils/logger/logAnalyticsEvent';
import { ConfirmationDialog } from '../confirmationDialog';
import { FileItem } from './fileItem';
import { UploadStatus } from './types';
import { fileToAwaitingUploadFile } from './utils';
import { WebCamera } from './webCamera';
import { usePreventPageLeave } from '../../hooks/usePreventPageLeave';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    fontWeight: '500',
};

const focusedStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
    backgroundColor: '#04C625',
    opacity: '0.5',
    color: 'black',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

export const FileUploader: React.FC = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const [files, setFiles] = useState<FileWithIdAndStatus[]>([]);

    const [showCamera, setShowCamera] = useState(false);
    const [selectedImage, setPreviewImage] = useState<null | File>(null);
    const [hasCamera, setHasCamera] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
    const { error, handleError } = useError();
    const { me } = useMe();
    const encryptedSimpleFormId = useRequiredEncryptedObjectIdParam(
        UrlParam.encryptedSimpleFormId,
    );
    const projectId = useRequiredObjectIdParam(UrlParam.projectId);
    const issueId = useRequiredObjectIdParam(UrlParam.issueId);
    const { fetchSimpleForm } = useSimpleForm();
    const [simpleForm, setSimpleForm] = useState<SimpleFormDto | null>(null);

    useFetchResource({
        fetchFn: fetchSimpleForm,
        dependencies: [encryptedSimpleFormId],
        params: { encryptedSimpleFormId },
        resourceName: 'projects',
        setData: setSimpleForm,
        onError: handleError,
    });
    const redirectPath = `/form/${encryptedSimpleFormId}/project/${projectId}/issue/${issueId}/organization/${simpleForm?.encryptedClientId}/user`;
    const alreadyUploadedFiles = files.filter(
        (file) => file.uploadStatus === UploadStatus.success,
    );

    const filesPendingForUpload = files.filter(
        (file) => file.uploadStatus === UploadStatus.pending,
    );
    usePreventPageLeave({
        isEnabled: filesPendingForUpload.length > 0,
    });
    const intl = useIntl();
    const { uploadFile } = useFile();
    const handleUpload = async () => {
        const updatedFiles = await Promise.all(
            files.map(async (file) => {
                logAnalyticsEvent({
                    category: GaCategory.Button,
                    action: GaAction.Click,
                    label: 'file-upload',
                    eventName: 'file-upload',
                });
                return await uploadFile(file, projectId, issueId);
            }),
        );
        setFiles(updatedFiles);
    };
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const handleGoToSummary = useCallback(async () => {
        if (filesPendingForUpload.length > 0) {
            setShowConfirmDialog(true);
            return;
        }

        navigate(redirectPath, { replace: false });
    }, [filesPendingForUpload.length, navigate, redirectPath]);

    const handleConfirmNavigation = () => {
        setShowConfirmDialog(false);
        navigate(redirectPath, { replace: false });
    };

    const onDrop = useCallback(
        (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            const newFiles = acceptedFiles.map(fileToAwaitingUploadFile);
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            rejectedFiles.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === 'file-too-large') {
                        console.log('file too large');
                        setErrors(`Error: ${err.message}`);
                    }

                    if (err.code === 'file-invalid-type') {
                        // setErrors(`Error: ${err.message}`);
                    }
                });
            });
        },
        [],
    );

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
    } = useDropzone({
        onDrop,
        multiple: true,
        maxFiles: 20,
        maxSize: 5242880,
    });
    const dropZoneStyle = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject],
    );
    const handleDelete = (file: File) => {
        setFiles(files.filter((f) => f !== file));
        if (selectedImage === file) {
            setPreviewImage(null);
        }
    };

    useEffect(() => {
        if (selectedImage && selectedImage.type.startsWith('image/')) {
            const img = new Image();
            img.src = URL.createObjectURL(selectedImage);
            img.onload = () => {
                const canvas = canvasRef.current;
                if (canvas) {
                    const ctx = canvas.getContext('2d');
                    if (ctx) {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);
                        ctx.strokeStyle = 'red';
                        ctx.lineWidth = 5;
                        ctxRef.current = ctx;
                    }
                }
            };
        }
    }, [selectedImage]);

    useEffect(() => {
        const checkForCamera = async () => {
            if (navigator.mediaDevices?.enumerateDevices) {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoInputDevices = devices.filter(
                    (device) => device.kind === 'videoinput',
                );

                if (
                    videoInputDevices.length > 0 &&
                    /Mobi/.test(navigator.userAgent)
                ) {
                    setHasCamera(true);
                }
            }
        };

        checkForCamera();
    }, []);

    if (error) {
        throw error;
    }

    me && identifyUserAndPage(me._id, PageName.fileUpload, me.email);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 6,
            }}
        >
            <Box sx={{ px: 4 }}>
                {/* Already uploaded files section */}
                {alreadyUploadedFiles.length > 0 && (
                    <Box sx={{ mb: 4 }} data-qa="sent-files">
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            {intl.formatMessage({
                                id: 'sent_files',
                            })}
                        </Typography>
                        <Grid container spacing={2}>
                            {alreadyUploadedFiles.map((file, index) => (
                                <FileItem
                                    key={`${file._id}-${index}`}
                                    file={file}
                                    onDelete={handleDelete}
                                />
                            ))}
                        </Grid>
                    </Box>
                )}

                {/* Upload zone */}
                <Box sx={{ mb: 4 }} data-qa="upload-zone">
                    <div {...getRootProps()} style={{ outline: 'none' }}>
                        <Box sx={dropZoneStyle}>
                            <input {...getInputProps()} name="dropzone" />
                            {isDragAccept && (
                                <Typography color="success.main">
                                    {intl.formatMessage({
                                        id: 'file_form_drop',
                                    })}
                                </Typography>
                            )}
                            {isDragReject && (
                                <Typography color="error.main">
                                    {intl.formatMessage({
                                        id: '"some_files_rejected"',
                                    })}
                                </Typography>
                            )}
                            {!isDragActive && (
                                <Typography
                                    color="text.secondary"
                                    sx={{
                                        whiteSpace: 'pre-line',
                                        textAlign: 'center',
                                    }}
                                >
                                    {intl.formatMessage({
                                        id: 'file_form_drop_or_select',
                                    })}
                                </Typography>
                            )}
                            {errors && (
                                <Typography
                                    color="error"
                                    sx={{
                                        fontSize: 14,
                                        mt: 1,
                                    }}
                                >
                                    {errors}
                                </Typography>
                            )}
                        </Box>
                    </div>
                </Box>

                {/* Pending files section */}
                {filesPendingForUpload.length > 0 && (
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            {intl.formatMessage({
                                id: 'files_pending_upload',
                            })}
                        </Typography>

                        <Grid container spacing={2}>
                            {filesPendingForUpload.map((file, index) => (
                                <FileItem
                                    key={`${file._id}-${index}`}
                                    file={file}
                                    onDelete={handleDelete}
                                    isPending
                                />
                            ))}
                        </Grid>
                    </Box>
                )}

                {/* Camera button */}
                {hasCamera && (
                    <Box
                        sx={{
                            mb: 4,
                            margin: 'auto',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                        data-qa="camera-button"
                    >
                        {!showCamera && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={buttonSx}
                                onClick={() => setShowCamera(true)}
                                data-qa="enable-camera"
                            >
                                {intl.formatMessage({
                                    id: 'enable_camera',
                                })}
                            </Button>
                        )}

                        {showCamera && (
                            <WebCamera
                                setFiles={setFiles}
                                files={files}
                                onClose={() => setShowCamera(false)}
                            />
                        )}
                    </Box>
                )}

                {/* Upload button */}
            </Box>
            <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleUpload}
                disabled={!filesPendingForUpload.length}
                // disabled={true}
                sx={buttonSx}
                data-qa="send-files"
            >
                {intl.formatMessage({
                    id: 'send_files',
                })}
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="large"
                sx={buttonSx}
                onClick={handleGoToSummary}
                data-qa="form-go-to-summary"
            >
                {intl.formatMessage({ id: 'form_go_to_summary' })}
            </Button>
            <ConfirmationDialog
                open={showConfirmDialog}
                title={intl.formatMessage({ id: 'unsent_files' })}
                message={intl.formatMessage(
                    { id: 'unsent_files_warning' },
                    { fileAmount: filesPendingForUpload.length },
                )}
                onConfirm={handleConfirmNavigation}
                onCancel={() => setShowConfirmDialog(false)}
                data-qa="unsent-files-confirmation"
            />
        </Box>
    );
};
