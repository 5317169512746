import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const CodeForm = () => {
    const [code, setCode] = useState('');
    const navigate = useNavigate();
    const intl = useIntl();

    const handleSubmit = () => {
        if (code.trim()) {
            navigate(`/form/${code}`);
        } else {
            alert(intl.formatMessage({ id: 'enter_form_code' }));
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                padding: 2,
            }}
        >
            <Typography variant="h5" gutterBottom>
                {intl.formatMessage({ id: 'enter_form_code' })}
            </Typography>

            <TextField
                label="Kod formularza"
                variant="outlined"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                sx={{ marginBottom: 2, width: '300px' }}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ width: '150px' }}
            >
                {intl.formatMessage({ id: 'confirm' })}
            </Button>
        </Box>
    );
};
