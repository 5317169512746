import { useAuth0 } from '@auth0/auth0-react';
import { Button, SxProps } from '@mui/material';
import { useIntl } from 'react-intl';
import hustro from '../../assets/hustro_color.jpg';
import { getRedirectLoginOptions } from './getRedirectLoginOptions';

export const HustroLoginButton = ({ sx }: { sx?: SxProps }) => {
    const { loginWithRedirect } = useAuth0();

    function loginWithHustro() {
        loginWithRedirect(
            getRedirectLoginOptions('Username-Password-Authentication'),
        );
    }
    const intl = useIntl();

    return (
        <Button
            variant="outlined"
            color="secondary"
            sx={sx}
            size="large"
            onClick={loginWithHustro}
            startIcon={<img height="20px" src={hustro} />}
            data-qa="hustro-login-button"
        >
            {intl.formatMessage({ id: 'login_page_hustro_login' })}
        </Button>
    );
};
