import { useEffect } from 'react';
import { useIntl } from 'react-intl';

interface UsePreventPageLeaveProps {
    isEnabled: boolean;
}

export const usePreventPageLeave = ({
    isEnabled,
}: UsePreventPageLeaveProps) => {
    const intl = useIntl();
    const message = intl.formatMessage({
        id: 'form_leave_page_message',
    });
    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isEnabled) {
                e.preventDefault();
                e.returnValue = message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isEnabled, message]);
};
