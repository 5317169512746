import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeColors = {
    yellowMain: '#FBCC30',
    green: '#4CAF50',
    blue: {
        main: '#315FA4',
        light: '#5E89D0',
        dark: '#1B3A75',
        contrastText: '#ffffff',
        25: '#F5F8FC',
        50: '#E7EEF7',
        100: '#C3D4EA',
        200: '#9BB8DD',
        300: '#739CD0',
        400: '#557DC6',
        500: '#315FA4', // main
        600: '#2B5593',
        700: '#244982',
        800: '#1D3D71',
        900: '#162F54',
    },
    black: '#000000',
};

export const themeOptions: ThemeOptions = {
    typography: {
        fontFamily: 'Archivo, sans-serif',
    },
    palette: {
        mode: 'light',
        primary: {
            main: themeColors.yellowMain,
            contrastText: themeColors.black,
        },
        secondary: themeColors.blue,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                outlined: {
                    backgroundColor: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: themeColors.blue[100],
                    },
                },
                root: {
                    maxWidth: '500px',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: '#FFFFFF',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: '#FFFFFF',
                    },
                },
                paper: {
                    backgroundColor: '#FFFFFF',
                },
            },
        },
    },
};

export const theme = createTheme(themeOptions);

export const buttonSx = {
    margin: 'auto',
    minWidth: {
        xs: '200px',
        sm: '400px',
    },
};
