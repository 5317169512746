import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useLogger } from './useLogger';

export const useAuthToken = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const logger = useLogger();
    const [
        isUserAuthenticatedWithHustroApp,
        setIsUserAuthenticatedWithHustroApp,
    ] = useState(false);
    const [isTokenLoading, setIsTokenLoading] = useState(false);

    const getToken = async () => {
        setIsTokenLoading(true);
        try {
            const token = await getAccessTokenSilently();
            if (token) {
                localStorage.setItem('token', token);
                localStorage.setItem('email', user?.email || ' ');
            }
        } catch (e: unknown) {
            //@ts-expect-error unknown auth0 error type
            if (e?.error === 'login_required') {
                return;
            }
            //@ts-expect-error unknown auth0 error type
            if (e.error === 'consent_required') {
                setIsUserAuthenticatedWithHustroApp(true);
            } else {
                //@ts-expect-error unknown auth0 error type
                logger.error('Error getting token', e);
            }
        } finally {
            setIsTokenLoading(false);
        }
    };

    return {
        getToken,
        isTokenLoading,
        isUserAuthenticatedWithHustroApp,
    };
};
