import { EncryptedObjectIdString } from '../../types/common';
import { ProjectDto } from '../../types/dto/project';
import {
    ApiClient,
    buildSimpleFormPath,
    createResourceService,
} from '../resourceService';
import { ProjectsErrorHandler } from './errorHandler';

export const getProjects = createResourceService<
    { encryptedSimpleFormId: EncryptedObjectIdString },
    ProjectDto[]
>({
    getPath: ({ encryptedSimpleFormId }) =>
        buildSimpleFormPath(encryptedSimpleFormId, 'project'),
    errorHandler: new ProjectsErrorHandler(),
    client: ApiClient.Unauthorized,
});
