import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { EmailCodeLoginButton } from '../components/auth/emailCodeLoginButton';
import { GoogleLoginButton } from '../components/auth/googleLoginButton';
import { HustroContinueButton } from '../components/auth/hustroContinueButton';
import { HustroLoginButton } from '../components/auth/hustroLoginButton';
import { buttonSx } from '../theme';

interface LoginFormProps {
    isUserAuthenticatedWithHustroApp: boolean;
    organizationLogoUrl?: string;
}

export const LoginForm = ({
    isUserAuthenticatedWithHustroApp,
    organizationLogoUrl,
}: LoginFormProps) => {
    const intl = useIntl();

    return (
        <Box>
            <img
                style={{ maxWidth: '60%', margin: 'auto', display: 'block' }}
                src={organizationLogoUrl}
            />
            <Box
                sx={{ mt: organizationLogoUrl ? 2 : 6, mx: 4 }}
                display="flex"
                flexDirection="column"
                gap={2}
            >
                <Typography sx={{ mt: 2, textAlign: 'center' }} variant="h4">
                    {intl.formatMessage({ id: 'login_page_welcome_message' })}
                </Typography>
                <Typography sx={{ mt: 2, textAlign: 'center' }} variant="h5">
                    {intl.formatMessage({ id: 'login_page_subheader' })}
                </Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    sx={{ mt: 4 }}
                >
                    {isUserAuthenticatedWithHustroApp && (
                        <HustroContinueButton sx={buttonSx} />
                    )}
                    <EmailCodeLoginButton sx={buttonSx} />
                    <GoogleLoginButton sx={buttonSx} />
                    {!isUserAuthenticatedWithHustroApp && (
                        <HustroLoginButton sx={buttonSx} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};
