import React from 'react';
import { englishTranslations } from '../utils/locale/englishTranslations';
import { ISO6391LanguageCode, TranslationsObject } from '../utils/locale/types';

export type IntlContextType = {
    messages: TranslationsObject;
    setLocale: (locale: ISO6391LanguageCode) => void;
};

export const IntlContext = React.createContext<IntlContextType>({
    messages: englishTranslations,
    setLocale: () => {},
});
