import { usePublicResource } from '../hooks/useResource';
import { organizationService } from '../services/organizationService';
import { EncryptedObjectIdString } from '../types/common';
import { OrganizationDto } from '../types/dto/organization';
import { OrganizationModel } from '../types/model/organization';

const dtoToModel = (
    { name, label, contactEmail, encryptedClientId }: OrganizationDto,
    logoSignedUrl?: string,
): OrganizationModel => ({
    name,
    label,
    contactEmail,
    logoSignedUrl,
    encryptedId: encryptedClientId,
});

const organizationsByHashMap = new Map<
    EncryptedObjectIdString,
    OrganizationModel
>();

interface OrganizationParams {
    encryptedClientId: EncryptedObjectIdString;
}

const fetchOrganizationWithLogo = async (
    token: string,
    params: OrganizationParams,
) => {
    const organizationData = await organizationService.getOrganization(token, {
        encryptedClientId: params.encryptedClientId,
    });

    let logoSignedUrl;
    if (organizationData.logoUsedInPdfHeaders) {
        logoSignedUrl = await organizationService.getOrganizationLogoSignedUrl(
            token,
            {
                encryptedClientId: params.encryptedClientId,
                logoId: organizationData.logoUsedInPdfHeaders,
            },
        );
    }

    return dtoToModel(organizationData, logoSignedUrl);
};

export const useOrganization = () => {
    const { fetchResource } = usePublicResource<
        OrganizationParams,
        OrganizationModel
    >({
        fetchFn: fetchOrganizationWithLogo,
        cacheMap: organizationsByHashMap,
        getCacheKey: ({ encryptedClientId }) => encryptedClientId,
    });

    return { fetchOrganization: fetchResource };
};
