import { EncryptedObjectIdString } from '../../types/common';
import { SimpleFormDto } from '../../types/dto/simpleForm';
import { ApiClient, createResourceService } from '../resourceService';
import { FormErrorHandler } from './errorHandler';

export const getSimpleForm = createResourceService<
    {
        encryptedSimpleFormId: EncryptedObjectIdString;
    },
    SimpleFormDto
>({
    client: ApiClient.Public,
    getPath: ({ encryptedSimpleFormId }) =>
        `/simpleForm/${encryptedSimpleFormId}`,
    errorHandler: new FormErrorHandler(),
});
