import { useEffect } from 'react';
import { useLogger } from './useLogger';

export const useAuthErrorHandler = () => {
    const logger = useLogger();

    useEffect(() => {
        if (window.location.search.includes('error')) {
            const urlParams = new URLSearchParams(window.location.search);
            const errorDescriptionFromUrl = urlParams.get('error_description');
            const errorMessageFromUrl = urlParams.get('error');

            logger.error(
                'Error caught by AuthWrapper',
                {
                    message: errorMessageFromUrl || '',
                    name: 'Unauthorized',
                },
                {
                    timestamp: Date.now(),
                    description: errorDescriptionFromUrl,
                },
            );
        }
    }, [logger]);
};
