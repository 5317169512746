import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import React from 'react';
import closeIcon from '../../assets/close.svg';
import { FileWithIdAndStatus } from '../../hooks/useFile';

interface FileItemProps {
    file: FileWithIdAndStatus;
    isPending?: boolean;
    onDelete: (file: File) => void;
}

export const FileItem: React.FC<FileItemProps> = ({
    file,
    isPending = false,
    onDelete,
}) => (
    <Grid item xs={12} sm={6} md={6} lg={4}>
        <Paper
            elevation={1}
            sx={{
                overflow: 'hidden',
                borderRadius: 1,
                boxShadow: 1,
                position: 'relative',
                height: 300,
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                    boxShadow: 3,
                    transition: 'box-shadow 0.2s ease',
                },
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    bgcolor: 'grey.100',
                    position: 'relative',
                    overflow: 'hidden',
                }}
                data-qa="file-item-image"
            >
                <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            </Box>

            <Box
                sx={{
                    bgcolor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
                data-qa="file-item"
            >
                <Typography
                    variant="caption"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        flexGrow: 1,
                        mr: 1,
                    }}
                >
                    {file.name}
                </Typography>

                {isPending && (
                    <IconButton
                        data-qa="delete-file"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete(file);
                        }}
                        sx={{
                            color: 'error.main',
                            p: 0.5,
                        }}
                    >
                        <img height="16px" src={closeIcon} alt="delete" />
                    </IconButton>
                )}
            </Box>
        </Paper>
    </Grid>
);
