import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSimpleFormIdFromLocalStorage } from '../utils/simpleFormId';
import { useAuth0 } from '@auth0/auth0-react';

export function RedirectToSimpleFormId() {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    useEffect(() => {
        const isBaseUrl = window.location.pathname === '/';
        console.log('isBaseUrl', isBaseUrl);
        if (isBaseUrl && isAuthenticated) {
            const encryptedSimpleFormIdToRedirectUserTo =
                getSimpleFormIdFromLocalStorage();
            if (encryptedSimpleFormIdToRedirectUserTo) {
                return navigate(
                    `/form/${encryptedSimpleFormIdToRedirectUserTo}`,
                );
            }
            navigate('/formCode');
        }
    }, [navigate, isAuthenticated]);

    return null;
}
